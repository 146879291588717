/* eslint-disable unused-imports/no-unused-imports-ts */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {HouseholdIncomeRange} from '@amplify/athena-models/dist/recommendation/householdIncomeRange';
import {makeAutoObservable} from 'mobx';

import Phase1Api from '../../api/phases/phase1Api';
import updateField from '../../helpers/updateField';
import {serializable} from '../sync/serialization';

class Phase1 {
    @serializable()
    features = ['savings'];

    @serializable()
    startIntent: 'today' | 'next1to2Months' | 'nextYear' | 'unsure' | '' = '';

    @serializable()
    overallHealth = '';

    @serializable()
    dateOfBirth = '';

    @serializable()
    dateOfBirthMixpanel = '';

    @serializable()
    zipCode = '';

    @serializable()
    zipCodeId = 5;

    @serializable()
    zipCodeState = 'California';

    @serializable()
    gender = '';

    @serializable()
    maritalStatus = '';

    @serializable()
    numberOfChildren = '0';

    @serializable()
    military: boolean | null = null;

    @serializable()
    annualHouseholdIncome: '' | HouseholdIncomeRange = '';

    @serializable()
    annualHouseholdIncomeText = '';

    @serializable()
    annualHouseholdIncomeNumeric = 0;

    @serializable()
    annualIndividualIncome = 0;

    @serializable()
    estimatedSavingTotal = 0;

    @serializable()
    travellingOut: boolean | null = null;

    @serializable()
    estimatedDebtTotal = 0;

    @serializable()
    prevOccupation = '';
    // Unemployed

    @serializable()
    employmentStatus = '';

    @serializable()
    unemploymentReasonType = '';

    @serializable()
    unemploymentReasonDesc = '';

    @serializable()
    prevEmployer = '';

    @serializable()
    workExpirienceYears = '';

    @serializable()
    workExpirienceMonths = '';

    // Retired
    @serializable()
    currIncomeDetails = '';

    @serializable()
    ageOfRetirement = '';

    // Student
    @serializable()
    schoolName = '';

    @serializable()
    grade = '';

    // On Disability
    @serializable()
    termSSDI = '';

    @serializable()
    amplifyVulPrequalify: boolean | null = null;

    @serializable()
    extremeSports = [];

    @serializable()
    usCitizen: boolean | null = null;

    @serializable()
    comfortableAmount = 0;

    @serializable()
    investmentRisk: 'high' | 'moderate' | 'low' | '' = '';

    constructor() {
        makeAutoObservable(this);
    }

    updateField = updateField.bind(this);

    get showInvestmentRisk(): boolean {
        return this.features.includes('savings');
    }

    get showNonAmplifyPrequalifyQuestion(): boolean {
        return this.amplifyVulPrequalify !== true;
    }

    checkZipCode = async (usersZipcode?: string): Promise<string> => {
        try {
            const res = await Phase1Api.checkZipCode(!!usersZipcode ? usersZipcode : this.zipCode);
            if (!!res?.name) {
                this.updateField('zipCodeId', res.id);
                this.updateField('zipCodeState', res.name);
                return '';
            } else {
                return 'Incorrect state';
            }
        } catch (e) {
            console.log(e);
        }
    };
}

export default Phase1;
